import {AbstractControl} from "@angular/forms";

export const dateValidator = (control: AbstractControl): {[key: string]: any} | null => {
    const dateFormatPattern = /^\d{4}-\d{2}-\d{2}$/;
    if (control.value && !dateFormatPattern.test(control.value)) {
        return { 'dateFormat': { value: control.value } };
    }

    if (control.value !== null){
        const [year, month, day] = control.value.split('-').map(Number);
        if (year < 1900 || year > 2099) {
            return { 'invalidYear': { value: control.value } };
        }

        if (month < 1 || month > 12) {
            return { 'invalidMonth': { value: control.value } };
        }

        const daysInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > daysInMonth) {
            return { 'invalidDay': { value: control.value } };
        }
    }

    return null;
}
